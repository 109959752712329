import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
// import Home from '../views/newHome.vue';

Vue.use(VueRouter);
console.log(store)
// const a = store.state.routePath;
const a = 'isPC';
 store.dispatch('setRoutePath', "isPic")

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/HomeNew.vue':'../views/moblie_home.vue'], resolve)
  },
  //医院  
  {
    path: '/hospital',
    name: 'hospital',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/hospital/index.vue':'../views/hospital/mobile/index.vue'], resolve)
  },
  {
    path: '/hospital/logistics',
    name: 'logistics',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/hospital/hospital_logistics.vue':'../views/hospital/mobile/hospital_logistics.vue'], resolve)
  },
  {
    path: '/hospital/information',
    name: 'information',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/hospital/hospital_information.vue':'../views/hospital/mobile/hospital_information.vue'], resolve)
  },
  {
    path: '/hospital/intelligence',
    name: 'intelligence',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/hospital/hospital_intelligence.vue':'../views/hospital/mobile/hospital_intelligence.vue'], resolve)
  },
  {
    path: '/hospital/operate',
    name: 'operate',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/hospital/hospital_operate.vue':'../views/hospital/mobile/hospital_operate.vue'], resolve)
  },
  {
    path: '/hospital/business',
    name: 'business',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/hospital/hospital_business.vue':'../views/hospital/mobile/hospital_business.vue'], resolve)
  },
  //药企
  {
    path: '/medicine',
    name: 'medicine',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/medicine/index.vue':'../views/medicine/mobile/index.vue'], resolve)
  },
  {
    path: '/medicine/logistics',
    name: 'medicine_logistics',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/medicine/medicine_logistics.vue':'../views/medicine/mobile/medicine_logistics.vue'], resolve)
  },
  {
    path: '/medicine/case',
    name: 'medicine_fbt_case',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/medicine/medicine_fbt_case.vue':'../views/medicine/mobile/medicine_fbt_case.vue'], resolve)
  },
  {
    path: '/medicine/platform',
    name: 'medicine_platform',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/medicine/medicine_platform.vue':'../views/medicine/mobile/medicine_platform.vue'], resolve)
  },
  {
    path: '/medicine/data',
    name: 'medicine_data',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/medicine/medicine_data.vue':'../views/medicine/mobile/medicine_data.vue'], resolve)
  },
  //案例
  {
    path: '/case',
    name: 'case',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: () => import('../views/case/index.vue'),
  },
  {
    path: '/case/fbt',
    name: 'case_fbt',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/case/case_fbt.vue':'../views/case/mobile/case_fbt.vue'], resolve)
  },
  {
    path: '/case/spd',
    name: 'case_spd',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/case/case_spd.vue':'../views/case/mobile/case_spd.vue'], resolve)
  },
  {
    path: '/case/cabinet',
    name: 'case_cabinet',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/case/case_cabinet.vue':'../views/case/mobile/case_cabinet.vue'], resolve)
  },
  {
    path: '/case/yly',
    name: 'case_yly',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/case/case_yly.vue':'../views/case/mobile/case_yly.vue'], resolve)
  },
  //走进传世
  {
    path: '/company/cs',
    name: 'company_cs',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/company/company_cs.vue':'../views/company/mobile/company_cs.vue'], resolve)
  },
  {
    path: '/company/lx',
    name: 'company_lx',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: () => import('../views/company/company_lx.vue'),
  },
  //传世新闻
  {
    path: '/news',
    name: 'news',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/news/index.vue':'../views/news/mobile/index.vue'], resolve)
  },
  {
    path: '/news/detail',
    name: 'newsDetail',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require([a == 'isPC'?'../views/news/news_detail.vue':'../views/news/mobile/news_detail.vue'], resolve)
  },
  //联系我们
  {
    path: '/contact',
    name: 'contact',
    meta:{
      title:'北京传世博润科技有限公司'
    },
    component: (resolve) => require(['../views/contact/mobile/index.vue'], resolve)
  },
 
];
const original = VueRouter.prototype.push

VueRouter.prototype.push = function push(localtion) {
  return original.call(this, localtion).catch(err => err)
}

export default routes;
