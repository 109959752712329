/**
 * @author QER
 * @date 2019/9/23
 * @Description: API前缀路径配置
 */

let route = '';
//判断开发环境or生产环境请求前缀
if (process.env.NODE_ENV === 'production') {
  route=`/api`
  // route=`http://43.254.1.27:8083`//演试  
  // route = 'http://49.4.23.228:9701';
  // route = "http://114.115.131.96:8071" //预演环境
  // route = 'http://192.168.2.122:8084'   //尹总
  // route = 'https://scmapi.csyaoly.com/' //正式
} else {
  route=`/api` 
  // route=`http://114.115.222.208:8082`//测试
  // route = `http://49.4.23.228:9701`; //测试
  // route = "http://114.115.131.96:8071" //预演环境
  // route = 'http://192.168.2.122:8082'   //肖
  // route = 'http://192.168.2.122:8084'   //尹总
  // route = 'https://scmapi.csyaoly.com/' //正式
}

export { route };
