<template>
  <div class="mobile-link-box">
    <div class="mobile-link-title">热门链接</div>
    <div class="mo-menu-box">
      <div
        class="mo-menu-box-list"
        v-for="(item, index) in navList"
        :key="index"
        @click.stop="getMobilePath(1, item.path, index)"
      >
        <span>{{ item.name }}</span>
        <i
          :class="item.show ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
          v-show="item.children && item.path !== '/company/cs'"
          @click.stop="toMobileExpand(item.show, index)"
        ></i>
        <div class="mo-second-menu-box" v-show="item.children && item.show && item.path !== '/company/cs'">
          <!-- <div class="mo-second-menu-expand"> -->
          <div
            class="mo-second-menu-list"
            v-for="(items, index1) in item.children"
            :key="index1"
            @click.stop="getMobilePath(1, items.path, index)"
          >
            {{ items.name }}
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navList: [
        {
          path: '/',
          name: '首页',
          show: false,
        },
        {
          path: '/hospital',
          name: '医院',
          show: false,
          children: [
            { path: '/hospital/logistics', name: '医院物流建设整体解决方案' },
            { path: '/hospital/information', name: '信息化产品' },
            { path: '/hospital/intelligence', name: '智能化设备' },
            { path: '/hospital/operate', name: 'SPD运营服务' },
            { path: '/hospital/business', name: 'SPD招商' },
          ],
        },
        {
          path: '/medicine',
          name: '药企',
          show: false,
          children: [
            { path: '/medicine/logistics', name: '物流中心建设整体解决方案' },
            { path: '/medicine/case', name: 'FBT一站式解决方案' },
            { path: '/medicine/platform', name: '供应链平台' },
            { path: '/medicine/data', name: '数据产品' },
          ],
        },
        {
          path: '/case/fbt',
          name: '经典案例',
          show: false,
          children: [
            { path: '/case/fbt', name: 'FBT案例介绍' },
            { path: '/case/spd', name: 'SPD案例介绍' },
            { path: '/case/cabinet', name: '高值耗材柜案例介绍' },
            { path: '/case/yly', name: '药链云案例介绍' },
          ],
        },
        {
          path: '/company/cs',
          name: '走进传世',
          show: false,
          children: [
            { path: '/company/cs', name: '传世科技介绍' },
            { path: '/company/lx', name: '链享科技介绍' },
          ],
        },
        { path: '/news', name: '传世新闻', show: false },
        { path: '/contact', name: '联系我们', show: false },
      ],
    };
  },
  methods: {
    toLink(link) {
      window.location.href = link;
    },
    toMobileExpand(show, index) {
      this.navList[index].show = !show;
    },
    getMobilePath(type, path, index) {
      // this.currentMainIndex = index;
      this.$router.push(path);
      localStorage.setItem('currentIndex', index);
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 769px){
  .mobile-link-box{
    display: none;
  }
}
.mobile-link-box {
  padding-bottom: .86rem;
  .mobile-link-title {
    font-size: 0.44rem;
    color: #3d3d3d;
    text-align: center;
    margin: 1.5rem 0 0.68rem;
  }
  .mo-menu-box {
    // position: absolute;
    width: 100%;
    // top: 1.2rem;
    // left: 0;
    // right: 0;
    text-align: left;
    padding: 0 0.4rem;
    font-size: 0.28rem;
    color: #3d3d3d;
    background: #fff;
    z-index: 999;
    box-sizing: border-box;
    .mo-menu-box-list {
      // height: 1.12rem;
      line-height: 1.12rem;
      border-bottom: 1px solid rgba(#b9b9b9, 0.3);
      .el-icon-arrow-down,
      .el-icon-arrow-right {
        float: right;
        margin-top: 0.48rem;
      }
      .mo-second-menu-box {
        .mo-second-menu-list {
          padding-left: 0.48rem;
          border-top: 1px solid rgba(#b9b9b9, 0.3);
        }
      }
      // border-bottom: 1px solid #B9B9B9;
    }
  }
}
</style>
