<template>
  <div class="header">
    <!-- PC -->
    <div class="header-contain">
      <img class="logo-img" @click="toHome()" src="../assets/images/logo_header.png" alt="" />
      <div class="nav-box">
        <div class="nav-list-block" v-for="(item, index) in navList" :key="index">
          <div :class="currentMainIndex == index ? 'nav-list nav-list-active' : 'nav-list'">
            <span class="nav-name" @click="toPath(1, item.path, index)">{{ item.name }}</span>
            <div class="nav-icon" v-show="item.children">
              <div class="arrow-down-box">
                <i class="el-icon-arrow-down"></i>
              </div>
              <div class="tab-list-box" v-show="item.children">
                <div
                  class="tab-list"
                  @click="toPath(2, items.path, index)"
                  v-for="(items, index1) in item.children"
                  :key="index1"
                >
                  <span>{{ items.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动 -->
    <div class="header-mobile">
      <div class="header-mobile-box">
        <img class="header-mobile-logo" @click="toHome()" src="../assets/mobile_images/mo_header_logo.png" alt="">
        <img class="header-mobile-icon" @click="getMobileMenu" :src="mobileMenuShow?require('../assets/mobile_images/mo_menu_close.png'):require('../assets/mobile_images/mo_menu_icon.png')" alt="">
      </div>
      <div class="mo-menu-box" v-show="mobileMenuShow">
        <div :class="currentMainIndex == index?'mo-menu-box-list mo-nav-active':'mo-menu-box-list'" v-for="(item,index) in navList" :key="index" @click.stop="getMobilePath(item.path, index)" >
          <span class="mo-nav-title">{{item.name}}</span> <i :class="item.show?'el-icon-arrow-down':'el-icon-arrow-right'" v-show="item.children&&item.path !== '/company/cs'" @click.stop="toMobileExpand(item.show,index)"></i>
          <div class="mo-second-menu-box" v-show="item.children&&item.show&&item.path !== '/company/cs'">
            <!-- <div class="mo-second-menu-expand"> -->
              <div class="mo-second-menu-list" v-for="(items, index1) in item.children"
                  :key="index1" @click.stop="getMobilePath(items.path, index)">
                {{items.name}}
              </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from '../App.vue';
export default {
  data() {
    return {
      currentMainIndex: 0,
      mobileMenuShow:false,
      navList: [
        {
          path: '/',
          name: '首页',
          show:false
        },
        {
          path: '/hospital',
          name: '医院',
          show:false,
          children: [
            { path: '/hospital/logistics', name: '医院物流建设整体解决方案'},
            { path: '/hospital/information', name: '信息化产品'},
            { path: '/hospital/intelligence', name: '智能化设备'},
            { path: '/hospital/operate', name: 'SPD运营服务'},
            { path: '/hospital/business', name: 'SPD招商'},
          ],
        },
        {
          path: '/medicine',
          name: '药企',
          show:false,
          children: [
            { path: '/medicine/logistics', name: '物流中心建设整体解决方案'},
            { path: '/medicine/case', name: 'FBT一站式解决方案'},
            { path: '/medicine/platform', name: '供应链平台'},
            { path: '/medicine/data', name: '数据产品'},
          ],
        },
        {
          path: '/case/fbt',
          name: '经典案例',
          show:false,
          children: [
            { path: '/case/fbt', name: 'FBT案例介绍'},
            { path: '/case/spd', name: 'SPD案例介绍'},
            { path: '/case/cabinet', name: '高值耗材柜案例介绍'},
            { path: '/case/yly', name: '药链云案例介绍'},
          ],
        },
        {
          path: '/company/cs',
          name: '走进传世',
          show:false,
          children: [
            { path: '/company/cs', name: '传世科技介绍'},
            { path: '/company/lx', name: '链享科技介绍'},
          ],
        },
        { path: '/news', name: '传世新闻' ,show:false},
        { path: '/contact', name: '联系我们' ,show:false},
      ],
      // navMoblieList: [
      //   {
      //     path: '/',
      //     name: '首页',
      //   },
      //   {
      //     path: '/hospital',
      //     name: '医院',
      //     children: [
      //       { path: '/hospital/logistics', name: '医院物流建设整体解决方案' },
      //       { path: '/hospital/information', name: '信息化产品' },
      //       { path: '/hospital/intelligence', name: '智能化设备' },
      //       { path: '/hospital/operate', name: 'SPD运营服务' },
      //     ],
      //   },
      //   {
      //     path: '/medicine',
      //     name: '药企',
      //     children: [
      //       { path: '/medicine/logistics', name: '物流中心建设整体解决方案' },
      //       { path: '/medicine/case', name: 'FBT一站式解决方案' },
      //       { path: '/medicine/platform', name: '供应链平台' },
      //       { path: '/medicine/data', name: '数据产品' },
      //     ],
      //   },
      //   {
      //     path: '/case/fbt',
      //     name: '经典案例',
      //     children: [
      //       { path: '/case/fbt', name: 'FBT案例介绍' },
      //       { path: '/case/spd', name: 'SPD案例介绍' },
      //       { path: '/case/cabinet', name: '高值耗材柜案例介绍' },
      //       { path: '/case/yly', name: '药链云案例介绍' },
      //     ],
      //   },
      //   {
      //     path: '/company/cs',
      //     name: '走进传世',
      //     children: [
      //       { path: '/company/cs', name: '传世科技介绍' },
      //       { path: '/company/lx', name: '链享科技介绍' },
      //     ],
      //   },
      //   { path: '/news', name: '传世新闻' },
      // ],
    };
  },
  watch: {
    $route: {
      handler(val, oldVal){
        this.navList.forEach((i, index) => {
        if(val.path == i.path ){
          this.currentMainIndex = index;
          localStorage.setItem('currentIndex', index);
        }else if(i.children){
          i.children.forEach(j=>{
            if(val.path == j.path ){
              this.currentMainIndex = index;
              localStorage.setItem('currentIndex', index);
            }
          })
        }
      });
      },
      // 深度观察监听
      deep: true
    }
  },
  created() {
    // if (localStorage.currentIndex) {
    console.log(window.location.pathname);
    this.navList.forEach((m, index) => {
      if (window.location.pathname.indexOf(m.path) > -1) {
        this.currentMainIndex = index;
        localStorage.setItem('currentIndex', index);
      }
    });
    // this.currentMainIndex = localStorage.currentIndex;
    // }
    // bus.$on('pathChange', msg => {
    //   this.navList.forEach((i, index) => {
    //     if(msg.path == i.path ){
    //       this.currentMainIndex = index;
    //       localStorage.setItem('currentIndex', index);
    //     }else if(i.children){
    //       i.children.forEach(j=>{
    //         if(msg.path == j.path ){
    //           this.currentMainIndex = index;
    //           localStorage.setItem('currentIndex', index);
    //         }
    //       })
    //     }
    //   });

    // });
  },
  methods: {
    toHome() {
      this.$router.push('/');
    },
    toPath(type, path, index) {
      if (path == '/company/lx') {
        window.location.href = 'http://csbrkjcom.huhuhu.net/index.html';
      } else {
        this.currentMainIndex = index;
        this.$router.push(path);
        localStorage.setItem('currentIndex', index);
      }
      // else if (path == '/medicine/case') {
      //   window.location.href = 'https://www.cs4pl.com/'
      // }
    },
    toMobileExpand(show,index){
      this.navList[index].show = !show;
    },
    getMobileMenu(){
      this.mobileMenuShow = !this.mobileMenuShow;
    },
    getMobilePath(path, index){
      this.currentMainIndex = index;
      this.$router.push(path);
      this.mobileMenuShow = false;
      localStorage.setItem('currentIndex', index);
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .header {
    height: 60px;
    .header-contain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
      height: 60px;
      margin: auto;
      img {
        width: 125px;
        height: 44px;
        cursor: pointer;
      }
    }
    .nav-box {
      display: flex;
      .nav-list-block {
        position: relative;
        z-index: 99999999;
        .nav-list {
          // position: relative;
          display: flex;
          align-items: center;
          line-height: 60px;
          padding: 0 34px;
          text-decoration: none;
          // cursor: pointer;
          color: #3d3d3d;
        }
        .nav-list-active {
          color: #fff;
          background: #4fa1a4;
        }
        .nav-icon .el-icon-arrow-down {
          display: block;
          cursor: pointer;
          margin-left: 10px;
        }
        .arrow-down-box {
          height: 60px;
          display: flex;
          align-items: center;
        }
        .nav-name {
          cursor: pointer;
        }
        .nav-icon:hover {
          .el-icon-arrow-down {
            transition: 0.5s;
            transform: rotate(180deg);
          }
          .tab-list-box {
            display: block;
          }
        }
        .tab-list-box {
          display: none;
          // opacity: 0;
          transition: 0.4s;
          position: absolute;
          top: 60px;
          width: 320px;
          color: #3d3d3d;
          background: #fff;
          text-align: left;
          z-index: 99999;
          box-sizing: border-box;
          // padding-top: 60px;
          left: 0px;
          // padding: 12px 0;
          .tab-list {
            height: 45px;
            line-height: 45px;
            text-align: left;
            cursor: pointer;
            padding-left: 36px;
            box-sizing: border-box;
            &:hover {
              // transition: 0.5s;
              color: #4fa1a4;
              // text-decoration: underline;
              // border-bottom: 3px solid #4fa1a4;
              // background: #4fa1a4;
              span {
                // transition: 0.3s;
                display: inline-block;
                line-height: 30px;
                border-bottom: 2px solid #4fa1a4;
                box-sizing: border-box;
              }
            }
            &:last-child:hover {
              transition: 0;
              color: #4fa1a4;
              span {
                transition: 0;
                display: inline-block;
                // padding-bottom: 5px;
                border-bottom: none !important;
                box-sizing: border-box;
              }
            }
          }
        }
      }
    }
    .header-mobile{
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .header{
    height: 1.2rem;
    position: relative;
    .header-contain{
      display: none;
    }
    .header-mobile{
      height: 100%;
      display: block;
      .header-mobile-box{
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0.4rem;
        box-sizing: border-box;
        .header-mobile-logo{
          width: 1.79rem;
          cursor: pointer;
        }
        .header-mobile-icon{
          width:0.36rem;
          cursor: pointer;
        }
      }
    }
    .mo-menu-box{
      position: absolute;
      width: 100%;
      height: calc(100vh - 1.2rem);
      top: 1.19rem;
      left: 0;
      right: 0;
      text-align: left;
      padding: 0 .4rem;
      font-size: .28rem;
      background: #fff;
      z-index: 999;
      overflow-y: auto;
      box-sizing: border-box;
      .mo-nav-active{
        .mo-nav-title{
          color: #4fa1a4;
        }
      }
      .mo-menu-box-list{
        // height: 1.12rem;
        line-height: 1.12rem;
        border-bottom: 1px solid rgba(#B9B9B9, 0.3);
        &:first-child{
          border-top: 1px solid rgba(185, 185, 185, 0.3);
        }
        .el-icon-arrow-down ,.el-icon-arrow-right{
          float: right;
          margin-top: .48rem;
        }
        .mo-second-menu-box{
          .mo-second-menu-list{
            padding-left: .48rem;
            border-top: 1px solid rgba(#B9B9B9, 0.3);
          }
        }
        // border-bottom: 1px solid #B9B9B9;
      }
    }
  }
}
</style>
