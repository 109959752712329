import './public-path';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import routes from './router'; 
import api from '@/uilt/new-axios.js'   // 记得改为你的路径
Vue.prototype.api = api;  // 此处命名为rq,你可以改
import "@/assets/style/style.css"
import "@/assets/style/reset.css"
import store from './store'
// import moment from "moment"
// Vue.prototype.$moment = moment;
// import {UrlSearch} from './uilt/urlSearch'//截取地址栏参数
// let Request = new UrlSearch();
// Vue.prototype.$Request = Request;
// import * as filter from './filter'
// import {preventReClick} from './uilt/preventReClick'//防止按钮多次点击
// Vue.use(preventReClick)
// Object.keys(filter).forEach(key => {
//   Vue.filter(key, filter[key])
// })
Vue.config.productionTip = false;
// Vue.prototype.$axios = axios;
Vue.use(ElementUI);

import { ImagePreview } from "vant";
// Vue.use(ImagePreview);
Vue.prototype.$ImagePreview = ImagePreview

// import echarts from 'echarts'
// import 'echarts/map/js/china.js';
// import 'echarts/extension-src/bmap/bmap'
// Vue.prototype.$echarts = echarts
// import md5 from 'js-md5'
// Vue.prototype.$md5 = md5


import CsRuler from 'cs-ruler'
import Axios from 'axios';
Vue.use(CsRuler)

// import Vant from 'vant'
// import 'vant/lib/index.css'
// Vue.use(Vant)
let router = null;
let instance = null;

function render(props = {}) {
  const { container } = props;
  router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? '/feCsbrWebsite' : '/',
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
      console.log(savedPosition)
      if(savedPosition) {
          return savedPosition
      } else {
          return {
              x: 0,
              y: 0
          }
      }
  }
  });
  //设置路由标题
  router.beforeEach((to, from, next) => {
    console.log(to);
    if (to.meta.title) {//如果设置标题，拦截后设置标题
      document.title = to.meta.title
    }
    next()
  })

// 路由守卫

// router.beforeEach((to, from, next) => {
//   if(to.path === '/'){
//     next('/Login');
//   }
//   // if(to.path === '/Login'){
//   //   next();
//   // }else if(to.path === '/lookReportpage'){
//   //   next();
//   // }else if(to.path === '/mobileView'){
//   //   next();
//   // }else{
//   //   let token =window.localStorage.token;
//   //   if(token ==='null' || token ==='' || token === undefined){
//   //     next('/Login');
//   //   }else{
//   //     next();
//   //   }
//   // }
// })

  instance = new Vue({
    store,
    router, 
    render: h => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app');
}

if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

function storeTest(props) {
  props.onGlobalStateChange &&
    props.onGlobalStateChange(
      (value, prev) => console.log(`[onGlobalStateChange - ${props.name}]:`, value, prev),
      true,
    );
  props.setGlobalState &&
    props.setGlobalState({
      ignore: props.name,
      user: {
        name: props.name,
      },
    });
}
 
export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}

export async function mount(props) {
  console.log('[vue] props from main framework', props);
  storeTest(props);
  render(props);
}

export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = '';
  instance = null;
  router = null;
}
