<template>
  <div class="footer">
    <!-- PC -->
    <div class="footer-box">
      <div class="footer-txt-box mb20">
        <div class="tel-link">
          <div class="tel-title">友情链接</div>
          <div class="tel-content">
            <p v-for="(item, index) in linkList" :key="index" @click="toLink(item.link)">{{ item.title }}</p>
          </div>
        </div>
        <div class="tel-person">
          <div class="tel-title">商务联系人</div>
          <div class="tel-content">
            <p><span class="mr30">王先生</span><span class="tel-width mr30">186-0712-6616</span><span>wangzhiwei@csbr.cn</span></p>
            <p><span class="mr30">曹先生</span><span class="tel-width mr30">188-7223-0383</span><span>weicao@csbr.cn</span></p>
          </div>
          <div class="tel-title mt30 mb24">公司地址</div>
          <div class="tel-content">
            <p><span class="mr30">北京市</span><span>西城区宣武门外大街庄胜广场办公楼西翼1410室</span></p>
            <p><span class="mr30">武汉市</span><span>江汉区武汉商务区泛海国际SOHO城7栋901室</span></p>
            <p class="mb0"><span class="mr30">威海市</span><span>环翠区联桥大厦503室</span></p>
          </div>
        </div>
        <div class="tel-logo">
          <img class="mb50" width="178" height="63" src="../assets/images/bottom_logo.png" alt="" />
          <div class="logo-box ">
            <img src="../assets/images/code_cs.png" alt="" />
            <p>扫码关注<br />传世公众号</p>
          </div>
          <div class="logo-box mt35">
            <img src="../assets/images/code_ll.png" alt="" />
            <p>扫码进入<br />恋链小程序</p>
          </div>
        </div>
      </div>
      <div class="copyright-box">北京传世博润科技有限公司 <a target="_blank" href="https://beian.miit.gov.cn">Copyright © 2015-2024 京ICP备2024044205号</a></div>
    </div>
    <!-- 移动 -->
    <div class="mo-footer-box">
      <div class="mo-code-title">扫码关注传世公众号</div>
      <div class="mo-code-img-box">
        <img src="../assets/images/code_cs.png" alt="" />
        <img src="../assets/images/code_ll.png" alt="" />
      </div>
      <div class="mo-tel-title">联系人</div>
      <div class="mo-tel-box">
        <p><span class="mr-064rem">（商务）王先生</span><span class="mr-064rem">186-0712-6616</span><span>wangzhiwei@csbr.cn</span></p>
        <p><span class="mr-064rem">（商务）曹先生</span><span class="mr-064rem">188-7223-0383</span><span>weicao@csbr.cn</span></p>
        <p><span class="mr-064rem">（展会）彭先生</span><span class="mr-064rem">189-9555-5542</span><span>pengjinyuan@csbr.cn</span></p>
      </div>
      <div class="mo-copy-name">北京传世博润科技有限公司</div>
      <div class="mo-copy-right"><a target="_blank" href="https://beian.miit.gov.cn">Copyright © 2015-2024 京ICP备2024044205号</a></div>
      <!-- <div class="mo-copy-right">Copyright © 2015-2024 京ICP备2024044205号</div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      linkList: [
        {
          title: '医链云',
          link: 'https://www.csylcloud.com',
        },
        {
          title: '药链云',
          link: 'http://117.78.35.198:8044/index.html',
        },
        {
          title: '四方云',
          link: 'https://www.cs4pl.com',
        },
        {
          title: '医药四方云',
          link: 'https://www.yao4pl.com',
        },
        {
          title: '恋链',
          link: 'https://ll.csyaoly.com',
        },
        {
          title: '传世链条',
          link: 'http://api.csbr.cn',
        },
        {
          title: '中电数据',
          link: 'http://www.cecdat.com',
        },
      ],
    };
  },
  methods: {
    toLink(link) {
      window.location.href = link;
    },
    toMobileExpand(show,index){
      this.navList[index].show = !show;
    },
    getMobilePath(type, path, index){
      // this.currentMainIndex = index;
      this.$router.push(path);
      localStorage.setItem('currentIndex', index);
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .footer {
    min-width: 1200px;
    background: #2a2b2c;
    padding: 35px 0;
    .footer-box {
      // display: flex;
      width: 1200px;
      margin: auto;
      .footer-txt-box {
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 16px;
        color: #fff;
        text-align: left;
        .tel-link {
          width: 22%;
          .tel-title {
            font-size: 20px;
            margin-bottom: 38px;
          }
          p {
            font-size: 14px;
            // line-height: 24px;
            margin-bottom: 16px;
            
            &:hover {
              cursor: pointer;
            }
            &:last-child {
              margin-bottom: 0 !important;
            }
          }
        }
        .tel-person {
          width: 53%;
          .tel-title {
            font-size: 20px;
            margin-bottom: 38px;
          }
          p {
            font-size: 14px;
            // line-height: 24px;
            margin-bottom: 18px;
            .tel-width{
              display: inline-block;
              width: 115px;
            }
          }
        }
        .tel-logo {
          width: 25%;
          padding-left: 70px;
          box-sizing: border-box;
          border-left: 1px solid #4B4B4B;
          .logo-box {
            display: flex;
            align-items: center;
            img {
              width: 70px;
              height: 70px;
              margin-right: 12px;
            }
            p {
              font-size: 16px;
            }
          }
        }
      }
      .copyright-box {
        font-size: 12px;
        color: #ffffff;
        opacity: 0.3;
        text-align: center;
        margin-top: 40px;
        a{
          color: #ffffff;
        }
      }
    }
    .mo-footer-box {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .footer {
    min-width: 0;
    color: #fff;
    background: #2a2b2c;
    margin: 0 auto;
    .footer-box {
      display: none;
    }
    .mo-footer-box{
      display: flex;
      flex-direction: column;
      align-items: center;
      .mo-code-title{
        font-size: 0.28rem;
        text-align: center;
        padding: .56rem 0 .42rem 0;
      }
      .mo-code-img-box{
        width: 3.3rem;
        display: flex;
        justify-content: space-between;
        img{
          width: 1.4rem;
        }
      }
      .mo-tel-title{
        font-size: .32rem;
        padding-top: .64rem;
      }
      .mo-tel-box{
        font-size: .22rem;
        margin-bottom: .64rem;
        p{
          margin-top: .38rem;
          text-align: left;
          display: flex;
        }
      }
      .mo-copy-name ,.mo-copy-right{
        font-size: .24rem;
        opacity: 0.3;
        line-height: .4rem;
      }
      .mo-copy-right{
        margin-bottom: .68rem;
        a{
          color: #fff;
        }
      }
    }
  }
}
.mr-064rem{
  margin-right: .64rem;
}
</style>
