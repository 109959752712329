<template>
  <div id="app" :style="{ height: screenHeight + 'px' }">
    <Header></Header>
    <router-view ></router-view>
    <mobileLink></mobileLink>
    <Footer></Footer>

    <!-- <keep-alive>
      需要缓存的视图组件
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive> -->
    <!-- 不需要缓存的视图组件 -->
    <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
  </div>
</template>

<script>
import Vue from 'vue';
export var bus = new Vue();
import Header from '@/components/header';
import Footer from '@/components/footer';
import mobileLink from '@/components/mobile_link';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    mobileLink
  },
  // provide() {
  //   return {
  //     reload: this.reload,
  //   };
  // },
  data() {
    return {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      isRouterAlive: true,
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      bus.$emit('pathChange', to);
      next();
    });
  },
  mounted() {
    let self = this;
    window.addEventListener('resize',function() { 
        this.screenHeight = window.innerHeight;
        const value = window.innerWidth > 768 ? 'isPC' : 'isMobile';
        // self.$router.go(0)
        this.$store.dispatch('setRoutePath', value);
        // this.reload();
      }.bind(this),
      200,
    );
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val;
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      setTimeout(() => {
        this.isRouterAlive = true;
      }, 0);
    },
  },
};
</script>
